// Width
$width-pc: 960px;
$width-tablet: 768px;
$width-smartphone: 480px;


// Font
$font: Helvetica, Arial, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
$font-serif: georgia,"times new roman","ヒラギノ明朝 Pro W6", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
$line-height: 1.5;


// Background Color
$bgColor: rgba(#FFF,1.0);


// Color
$color: rgba(#333,1.0);
$color-link: rgba(#00BFFF,1.0);


// Text Highlight
$color-inverse: rgba(#fff,1.0);
$color-inverse-bg: rgba(#00BFFF,1.0);