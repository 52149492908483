body {
	line-height: 2;
	padding-top: 75px;
	&.home {
		padding-top: 0;
	}
}



/*-----------------------------------------------------*/
// header
/*-----------------------------------------------------*/

.header {
	// SPNAVI
	// @include spnav_btn();
}


/*-----------------------------------------------------*/
// nav
/*-----------------------------------------------------*/

.nav {
	// SPNAVI
	// @include spnav_content();
}



/*-----------------------------------------------------*/
// footer
/*-----------------------------------------------------*/

#footer {
    padding: 30px 6%;
}

.footer {
	@include e(first) {
		display: block;
		text-align: center;
	}
	@include e(logo){
		margin: 40px auto 30px auto;
		padding: 0;
	}

	@include e(second) {
		display: block;
		text-align: center;
	}
	@include e(address) {
		margin-bottom: 10px;
	}
	@include e(link) {
		justify-content: center;
	}
}

%visual_base_sp {
	position: fixed;
	display: block;
	content: '';

	height: 100vh;
	width: 100vw;
	background-repeat: no-repeat;
	background-position: bottom 25vh right -17vw;
	background-size: auto 100vw;
	z-index: -1;
	top: 0;
	left: 0;

}

body.home {

	&:after {
		background-image: url(../images/visual_white.png);
		@extend %visual_base_sp;

	}
}

.hero {
	@include e(inner) {
		&:after {
			@extend %visual_base_sp;
			background-image: url(../images/visual_green.png);
			z-index: 0;
		}
	}
}

.read_title_img {
	top: 18vh;
}

.join {
	width: 90%;
}

.scroll_wrap {
	display: none;
}

.read {
	br {
		display: none;
	}
}

.middletitle {
	font-size: 80px;
	top: 20px;
}

.subtitle {
	text-align: center;
    font-size: 20px;
}

.top_service {
	padding: 50px 0 0 0;
	@include e(wrap) {
		display: block;
	}
	@include e(content) {
		width: 100%;
	}
	@include e(ph) {
		width: 100%;
		margin-top: 30px;
	}
}

.link {
	font-size: 15px;
}

.top_join {
	padding: 50px 0 20px 0;
	margin-top: -40px;
	.middletitle {
		font-size: 80px;
		top: 20px;
	}
	.home & {
		margin-top: 0;
	}
	@include e(wrap) {
		display: block;
	}
	@include e(content) {
		width: 100%;
		.link_wrap {
			justify-content: center;
		}
		.read_link {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	@include e(ph) {
		width: 100%;
		margin-top: 30px;
		text-align: center;
		img {
			position: static;
			width: 70%;
			margin: 0 auto;
			transform: translateX(0);
		}
	}
}




.titlearea {
	margin-top: -50px;
	.inner {
		position: relative;
		padding-top: 40px;
	}
	@include e(main) {
		font-size: 25px;
	}
	@include e(read) {
		font-size: 10px;
	}
	@include e(en) {
		font-size: 90px;
		&:after {
			height: 20px;
		}
	}
}

.section {
	margin: 50px 0;
}

.service {

	@include e(intro) {
		br {
			display: none;
		}
	}
	@include e(list) {
		margin: 30px 0;
		@include e(wrap) {
			display: block;
			margin: 49px 0;
		}
		@include e(title) {
			width: 100%;
		}
		@include e(content) {
			width: 100%;
		}
		.subtitle {
			text-align: left;
		}
	}
}


.company {
	@include e(philosophy) {
		@include e(wrap) {
			padding: 30px 0;
		}
		@include e(no) {
			font-size: 30px;
			width: 45px;
		}
		@include e(content) {
			width: calc(100% - 45px);
			padding-left: 20px;
		}
		@include e(title) {
			margin: 0 0 20px 0;
			text-align: left;
		}
	}
}


.table {
	display: block;
	tbody, th, th, td {
		display: block;
	}
	tr {
		th {
			white-space: default;
			padding-right: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
		&:first-child {
			th, td {
				border-top: 1px solid #E0E2E9;
				border-top: none !important;
			}
		}
	}
}

.contact {
	@include e(table) {
		display: block;
		tbody, tr, th, td {
			display: block;
		}
		tr {
			th {
				padding-bottom: 0;
				border-bottom: none;
				padding-left: 0;
				padding-right: 0;
			}
			td {
				padding-top: 0;
				padding-left: 0;
				padding-right: 0;
			}
			&:first-child {
				th {
					padding-top: 0;
				}
			}
		}
	}
}

.recruit {
	@include e(list) {
		@include e(all) {
			margin-top: 30px;
		}
		@include e(title) {
			padding-left: 35px;
			@include e(wrap) {
				display: block;
				&:after {
					left: 10px;
				}
			}
			@include e(first) {
				display: block;
				font-size: 17px;
			}
			@include e(second) {
				display: block;
				padding-left: 0;
			}
		}
		@include e(content) {
			padding-left: 0;
		}
	}
	@include e(interviewlist) {
		@include e(content) {
			display: block !important;
		}
		@include e(ph) {
			display: none;
		}
		@include e(icon) {
			display: block;
			width: 50px;
			height: 50px;
			border-radius: 100%;
			background-position: center;
			background-size: cover;
			margin-right: 10px;
		}
		@include e(main) {
			width: 100%;
			padding: 40px 8%;
		}
		@include e(read) {
			font-size: 18px;
		}
		@include e(name) {
			font-size: 16px;
		}
		@include e(job) {
			font-size: 10px;
		}
		@include e(wrap) {
			.slick-arrow {
				display: none !important;
			}
		}
	}
}


.interview {
	h2, h3, h4 {
		font-size: 18px;
		background-position: left 8px;
	}
	@include e(link) {
		padding: 30px 0;
		a {
			padding: 10px;
		}
	}

	@include e(profile) {

		padding: 30px 6%;
		border-radius: 0;

		@include e(pc) {
			display: none;
		}
		@include e(sp) {
			display: block;
		}

		@include e(all) {
			.inner {
				padding: 0;
			}
		}

		@include e(ph) {
			width: 100px;
			height: 100px;
			margin-right: 20px;
		}
		@include e(content) {
			width: calc(100% - 120x);
		}
		@include e(name) {
			margin-top: -0.2em;
		}
		@include e(description) {
			font-size: 14px;
			margin-top: 20px;
		}
	}
}
