// Common

html,body {
	font-size: 62.5%;
}
body {
	background: $bgColor;
	color: $color;
	font-family: $font;
	line-height: $line-height;
}

::selection {
	color: $color-inverse;
	background: $color-inverse-bg;
}

a {
	color: $color-link;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		color: lighten($color-link, 20%);
		text-decoration: none;
	}
	&:focus {
		outline:none;
	}
	transition: all .3s;
}

a:focus, *:focus { outline:none; }

// Print
@media print {
	* html body {
		zoom: .7;
	}
}

* {
	box-sizing: border-box;
}