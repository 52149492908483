@charset "utf-8";

/* Default */
@import "__normalize";
@import "__mixin";

/* Adjust if you needed */
@import "_variable";
@import "_default";
@import "_zindex";

/* [ Sample ] */
// FontSize        - @include fz($size: 14);
// Clearfix        - @extend %clearfix;
// No List Style   - @extend %nolist;

// Width > %       - @include width2p(640, 300);
// Height > %      - @include height2p(500, 300);
// Margin > %      - @include margin2p(640, 20, 5, 20, 5);
// Padding > %     - @include padding2p(640, 20, 5, 20, 5);

// Possition > %   - @include left2p(640, 50);
//                 - @include top2p(640, 50);
//                 - @include right2p(640, 50);
//                 - @include bottom2p(640, 50);

body {
	line-height: 2.4;
	font-size: 16px;
	padding-top: 100px;
	&.home {
		background-color: #F5F5F5;
		padding-top: 0;
	}
}

.w1000 {
	display: none;
}

@media only screen and (max-width: 1000px){


	.w1000 {
		display: block !important;
	}
}


/*-----------------------------------------------------*/
// header
/*-----------------------------------------------------*/

.header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: z(header);
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	@include e(logo) {
		width: 61%;
		margin: 0;
		padding: 27px 20px 0 27px;
		max-width: 280px;
		line-height: 0;
		img {
			width: 100%;
			height: auto;
		}
		a {
			&:hover {
				opacity: .5;
			}
		}
	}
}



/*-----------------------------------------------------*/
// nav
/*-----------------------------------------------------*/

.name {
	text-align: right;
	font-weight: bold;
}

.nav {
	@include e(wrap) {
		display: flex;
		align-items: center;
	}
	@include e(list) {
		@extend %nolist;
		display: flex;
		font-size: 15px;
		margin-right: 10px;
		a {
			display: inline-block;
			color: #000;
			text-decoration: none;
			padding: 1px 35px;
			border-right: solid 1px #BEBEBE;
			&:hover {
				color: #72BD58;
			}
			&.active {
				color: #2F9667;
				span {
					position: relative;
					&:after {
						content: '';
						display: block;
						position: absolute;
						left: 0;
						right: 0;
						bottom: -10px;
						height: 3px;
						background: rgb(46,149,103);
						background: linear-gradient(90deg, rgba(46,149,103,1) 0%, rgba(114,189,88,1) 100%);

					}
				}
			}
		}
		li {
			&:last-child {
				a {
					border-right: none;
				}
			}
		}
	}
	@include e(contact) {
		a {
			padding: 30px 50px;
			background-color: #2F9667;
			color: #fff;
			text-decoration: none;
			font-size: 15px;
			display: block;
			span {
				background-image: url(../images/icon_contact.svg);
				background-repeat: no-repeat;
				background-size: 20px auto;
				padding-left: 35px;
				background-position: left center;
			}
			&:hover {
				background-color: #72BD58;
			}
		}
	}
}


@media only screen and (max-width: 1050px){
	.header {
		@include e(logo) {
			max-width: 242px;
			padding: 14px;
		}
	}
    .nav {
		@include e(contact) {
			a {
				font-size: 14px;
				padding: 23px 38px;
			}
		}
		@include e(list) {
			a {
				font-size: 14px;
				padding: 1px 31px;
			}
		}
	}
}


@media only screen and (max-width: 920px){
	.header {
		@include spnav_btn();
		@include e(logo) {
			padding: 17px;
		}
	}
    .nav {
		@include spnav_content();
		@include e(wrap) {
			display: block;
		}
		@include e(list) {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -60%);
			display: block;
			width: 80%;
			border-top: solid 1px #fff;

			a {
				display: block;
				border-right: none;
				color: #fff !important;
				border-bottom: solid 1px #fff;
				padding: 20px 15px;
				background-image: url(../images/arrow_white.svg);
				background-repeat: no-repeat;
				background-size: 10px;
				background-position: right 10px center;
				font-size: 15px;
				span {

					&:after {
						display: none !important;
					}
				}
			}
		}
		@include e(contact) {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			a {
				font-weight: bold;
				background-color: #fff;
				color: #2F9667;
				text-align: center;
				span {
					background-image: url(../images/icon_contact_green.svg);
				}
			}
		}
	}
}


/*-----------------------------------------------------*/
// hero
/*-----------------------------------------------------*/

%visual_base {
	position: fixed;
	display: block;
	content: '';

	height: 100vh;
	width: 100vw;
	background-repeat: no-repeat;
	background-position: bottom 0 right 0;
	background-size: auto 80vh;
	z-index: -1;
	top: 0;
	left: 0;

}

.hero {
	height: 100vh;
	background-color: #fff;
	width: 100vw;
	height: 100vh;
	@include e(inner) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: 100vh;
		clip-path: inset(0);
		&:after {
			@extend %visual_base;
			background-image: url(../images/visual_green.png);
			z-index: 0;
		}
	}
}

body.home {

	&:after {
		background-image: url(../images/visual_white.png);
		@extend %visual_base;

	}
}

.visual {
}

.join {
	position: fixed;
	right: 20px;
	bottom: 20px;
	max-width: 400px;
	width: 80%;
	z-index: z(join);
	img {
		width: 100%;
		height: auto;
	}
	@include e(link) {
		display: block;
		position: relative;
		padding: 20px 30px;
		background-color: rgba(#087e48, .68);
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
		/* Note: currently only Safari supports backdrop-filter */
		backdrop-filter: blur(10px);
		--webkit-backdrop-filter: blur(10px);
		line-height: 0;
		// background-color: rgba(0, 0, 0, 0);
		/* (plus shape's fill blended on top as a separate layer with 67.86% opacity) */
		&:hover {
			background-color: rgba(#2F9667, 1);
		}
	}

	@include e(close) {
		position: absolute;
		right: -15px;
		top: -15px;
		width: 45px;
		cursor: pointer;
	}

}


.inner {
	padding: 0 6%;
	max-width: 1300px;
	margin: 0 auto;
	@include e(white) {
		background-color: #fff;
		z-index: 1;
		position: relative;
	}
}

.scroll_wrap {
	position: fixed;
	left: 8%;
	bottom: 0;
	padding-bottom: 90px;
	transition: all, .6s;
	&.active {
		opacity: 0;
	}
}


.scroll-line {
	position: absolute;
	left: 50%;
	bottom: 0;
	width: 1px;
	height: 80px;
	background-color: rgba(#2f9667, .3);
	transition: all 1.4s;
}
.scroll-line::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	width: 1px;
	background-color: #2f9667;
	-webkit-animation: lineMove 2.0s ease infinite;
	animation: lineMove 2.0s ease infinite;
}


@keyframes lineMove {
	0% {
		bottom: 100%;
		height: 0;
	}
	40% {
		bottom: 0;
		height: 100%;
	}
	100% {
		bottom: 0;
		height: 0;
	}
}

/*-----------------------------------------------------*/
// read
/*-----------------------------------------------------*/

.read {
	padding: 150px 0;
	// z-index: 1;
	// background-color: #F5F5F5;
	// position: relative;
	&:after {

		// background-image: url(../images/visual_white.png);
		z-index: 0;
	}
	@include e(title) {
		position: relative;
		img {
			width: 80vw;
			max-width: 450px;
		}
		@include e(img) {
			// position: absolute;
			position: fixed;
			top: 15vw;
		}
		@include e(original) {
			opacity: 0;
		}
		&.active {
			.read_title_img {
				opacity: 0;
			}
			.read_title_original {
				opacity: 1;
			}
		}
	}
	@include e(link) {
		margin-top: 40px;
	}
}

.link {
	display: inline-block;
	padding-right: 50px;
	font-size: 16px;
	color: #000;
	text-decoration: none;
	transition: all, .3s;
	position: relative;
	span {
		border-bottom: solid 1px #000;
		padding-bottom: 3px;
	}
	&:before {
		display: block;
		content: '';
		width: 40px;
		height: 40px;
		right: 0;
		background-image: url(../images/arrow_white.svg);
		background-repeat: no-repeat;
		background-size: 10px;
		position: absolute;
		z-index: 1;
		background-position: center 14px;
	}
	&:after {
		display: block;
		content: '';
		width: 40px;
		height: 40px;
		border-radius: 100%;
		background-color: #2f9667;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		transition: all, .3s;
	}
	&:hover {
		color: #000;
		&:after {
			transform: translateY(-50%) scale(1.2);
		}
	}
	@include e(w) {
		color: #fff;
		span {
			border-bottom: solid 1px #fff;
		}
		&:before {
			background-image: url(../images/arrow_green.svg);
		}
		&:after {
			background-color: #fff;
		}
		&:hover {
			color: #fff;
		}
	}
	@include e(wrap) {
		display: flex;
		flex-wrap: wrap;
		.link, .read_link {
			margin-right: 50px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}




@media (orientation: portrait){


	%visual_base_po {
		position: fixed;
		display: block;
		content: '';

		height: 100vh;
		width: 100vw;
		background-repeat: no-repeat;
		background-position: bottom 25% right -17vw;
		background-size: auto 100vw;
		z-index: -1;
		top: 0;
		left: 0;

	}

	body.home {

		&:after {
			background-image: url(../images/visual_white.png);
			@extend %visual_base_po;

		}
	}

	.hero {
		@include e(inner) {
			&:after {
				@extend %visual_base_po;
				background-image: url(../images/visual_green.png);
				z-index: 0;
			}
		}
	}

	.read_title_img {
		top: 18vh;
	}
}



/*-----------------------------------------------------*/
// footer
/*-----------------------------------------------------*/

#footer {
	background: linear-gradient(#26554c 0%, #173b35 100%);
	padding: 50px 6%;
	color: #fff;
	position: relative;
	z-index: z(footer);
	.scrl_top.scrl_end & {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.form_station {
	p {
		display: flex;
		align-items: center;
		span {
			padding: 0 5px;
		}
	}
}

.footer {
	@include e(first) {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}

	@include e(logo) {
		width: 61%;
		margin: 0;
		padding: 0 0 20px 0;
		max-width: 250px;
		img {
			width: 100%;
			height: auto;
		}
		a {
			&:hover {
				opacity: .5;
			}
		}

	}
	@include e(link) {
		@extend %nolist;
		display: flex;
		a {
			font-size: 14px;
			color: #fff;
			text-decoration: none;
			margin-left: 15px;
			&:before {
				display: inline-block;
				content: '/';
				padding-right: 15px;
				color: #fff;
			}
			&:hover {
				color: #72BD58;
			}
		}
		li {
			&:first-child {
				a {
					margin-left: 0;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	@include e(second) {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
	}
}


.main {
	position: relative;
	z-index: z(main);
	background-color: #fff;
}

.top_service {
	padding: 130px 0;
	position: relative;
	@include e(wrap) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	@include e(content) {
		width: 470px;
	}
	@include e(ph) {
		text-align: right;
		width: calc(100% - 470px);
		img {
			width: 100%;
			height: auto;
			max-width: 600px;
		}
	}
}


@media only screen and (max-width: 1080px){

	.top_service {
		padding-bottom: 0;
	}
}


@media only screen and (max-width: 1000px){

	.top_service {
		@include e(wrap) {
		}
		@include e(content) {
			width: 48%;
			br {
				display: none;
			}
		}
		@include e(ph) {
			width: 48%;
		}
	}
}


.top_join {
	padding: 20px 0 80px 0;
	position: relative;
	margin-top: 150px;
	.home & {
		margin-top: 0;
	}
	.middletitle {
		left: auto;
		right: 0;
		top: -82px;
	}
	@include e(wrap) {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
	}
	@include e(content) {
		width: 490px;
	}
	@include e(ph) {
		width: calc(100% - 600px);
		position: relative;
		text-align: center;
		img {
			width: 100%;
			height: auto;
			max-width: 446px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -140px;
			z-index: 1;
		}
	}
	@include e(links) {
		background-color:#429B73;
		padding: 10px 0 40px 0;
	}
}

@media only screen and (max-width: 1135px){

	.top_join {
		padding-bottom: 40px;
		@include e(ph) {
			width: calc(100% - 547px);
			img {
				bottom: -80px;
			}
		}
	}
}

@media only screen and (max-width: 1000px){

	.top_join {
		@include e(wrap) {
		}
		@include e(content) {
			width: 48%;
			br {
				display: none;
			}
		}
		@include e(ph) {
			width: 45%;
		}
	}
}



.middletitle {
	font-size: 160px;
	color: #F5F5F5;
	font-family: 'Oswald', sans-serif;
	position: absolute;
	left: -5%;
	top: 30px;
	z-index: 0;
	line-height: 1;
}

%icontitle {
	padding-left: 14px;
	background-image: url(../images/icon_title.svg);
	background-repeat: no-repeat;
	background-size: 5px auto;
	background-position: left 4px;
	font-size: 22px;
}

.subtitle {
	font-size: 26px;
	line-height: 1.5;
	position: relative;
	z-index: 1;
	@include e(icon) {
		transform: translateY(-7px);
		span {
			@extend %icontitle;
		}
	}
}


.titlearea {
	margin: -40px 0 0 0;
	.inner {
		position: relative;
		padding-top: 70px;
	}
	@include e(main) {
		font-size: 46px;
		margin: 0;
		line-height: 1;
		padding-top: 20px;
		letter-spacing: .05em;
	}
	@include e(read) {
		font-size: 14px;
		color: #2E9567;
		margin-top: 10px;
		line-height: 1;
	}
	@include e(en) {
		font-size: 160px;
		color: #F5F5F5;
		font-family: 'Oswald', sans-serif;
		position: absolute;
		left: 10%;
		top: 0;
		z-index: -1;
		line-height: 1;
		width: 90%;
		overflow: hidden;
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 40px;
			background-color: #fff;
		}
	}
}

.breadcrumbs {
	text-align: right;
	font-size: 10px;
	padding: 10px 20px;
	position: relative;
	z-index: 1;
	span[typeof="ListItem"] {
		&:after {
			font-family: 'Oswald', sans-serif;
			display: inline-block;
			content: '>';
			padding: 0 10px;
			color: #BEBEBE;
			font-size: 11px;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
	.home {
		span[property="name"] {
			font-size: 0;
			color: transparent;
			&:before {
				content: '';
				display: inline-block;
				width: 11px;
				height: 11px;
				background-image: url(../images/icon_home.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
	}
	a {
		color: #000;
		&:hove {
			color: #000;
			opacity: .5;
		}
	}
}

.center {
	text-align: center;
}



.section {
	margin: 80px 0;
	@include m(gray) {
		background-color: #F5F5F5;
	}
}

.service {
	@include e(read) {
		padding-bottom: 30px;
	}
	@include e(ph) {
		text-align: center;
		line-height: 1;
		position: relative;
		&:after {
			display: block;
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			background: #fff;
			height: 50%;
		}
		img {
			width: 80%;
			max-width: 600px;
			position: relative;
			z-index: 1;
		}
	}
	@include e(list) {
		margin: 50px auto;
		max-width: 900px;
		@include e(wrap) {
			display: flex;
		}
		@include e(title) {
			width: 240px;
			padding-top: 0px;
		}
		@include e(content) {
			margin-top: 10px;
			width: calc(100% - 240px);
		}
	}
}


.company {
	@include e(intro) {
		display: flex;
		align-items: center;
		@include e(title) {
			width: 500px;
		}
		@include e(read) {
			width: calc(100% - 500px);
		}
	}
	@include e(visual) {
		height: 400px;
		background-image: url(../images/company_visual.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top right;
	}
	@include e(philosophy) {
		margin: 0 auto;
		max-width: 900px;
		@include e(wrap) {
			display: flex;
			border-bottom: solid 1px #E8E6E6;
			padding: 40px 0;
			&:first-child {
				border-top: solid 1px #E8E6E6;
			}
		}
		@include e(no) {
			color: #2E9567;
			font-size: 45px;
			font-family: 'Oswald', sans-serif;
			line-height: 1;
			width: 80px;
		}
		@include e(content) {
			width: calc(100% - 80px);
			padding-left: 30px;
			border-left: solid 1px #E8E6E6;
		}
		@include e(title) {
			margin: 0 0 20px 0;
		}
	}
	@include e(table) {
		margin: 0 auto;
		max-width: 900px;
	}
	@include e(info) {
		padding: 50px 0;
	}
}


@media only screen and (max-width: 1120px){


	.company {
		@include e(intro) {
			display: block;
			@include e(title) {
				width: 100%;
			}
			@include e(read) {
				width: 100%;
			}
		}
		@include e(visual) {
			height: 300px;
		}
	}

}


.table {
	border-collapse:  collapse;
	width: 100%;
	tr {
		th, td {
			padding: 20px 20px;
			text-align: left;
			border-bottom: solid 1px #E0E2E9;
		}
		th {
			white-space: nowrap;
			padding-right: 50px;
		}
		&:first-child {
			th, td {
				border-top: 1px solid #E0E2E9;
			}
		}
	}
}


.contact {
	@include e(flow) {
		display: flex;
		margin-top: 40px;
		margin-bottom: -20px;
		@include e(content) {
			font-size: 16px;
			padding-right: 70px;
			position: relative;
			color: #ccc;
			&:after {
				display: block;
				content: '';
				width: 30px;
				height: 1px;
				background-color: #ccc;
				position: absolute;
				right: 20px;
				top: 50%;
			}
			&:last-child {
				padding-right: 0;
				&:after {
					display: none;
				}
			}
		}
		@include e(active) {
			color: #087e48;
			font-weight: bold;
		}
	}
	@include e(form) {
		padding: 50px 0;
	}
	@include e(table) {

		border-collapse:  collapse;
		width: 100%;
		tr {
			th, td {
				padding: 20px 20px;
				text-align: left;
				border-bottom: solid 1px #E0E2E9;
				vertical-align: top;
				input[type="text"], input[type="tel"], input[type="email"], textarea {
					width: 100%;
					padding: 15px 20px;
					border-radius: 5px;
					border: none;
				}
				.must {
					&:after {
						display: inline-block;
						content: '必須';
						background-color: #C30D23;
						padding: 5px 8px;
						border-radius: 5px;
						color: #fff;
						font-size: 10px;
						line-height: 1;
						margin-left: 10px;
						transform: translateY(-2px);
					}
				}
			}
			th {
				white-space: nowrap;
				padding-right: 50px;
			}
			// &:first-child {
			// 	th, td {
			// 		border-top: 1px solid #E0E2E9;
			// 	}
			// }
		}
	}
	@include e(send) {
		text-align: center;
		margin-top: 40px;
		input {
			background-color: #2f9667;
			border: none;
			color: #fff;
			padding: 20px 50px;
			cursor: pointer;
			transition: all, .3s;
			margin: 0 10px;
			display: inline-block;
			&:hover {
				background-color: #72BD58;
			}
			&.wpcf7-previous {
				background-color: #99A4B7;
			}
		}
		.wpcf7-spinner {
			display: none !important;
		}
	}
}


.recruit {
	@include e(inner) {
		position: relative;
	}
	@include e(content) {
		width: 50%;
		margin-left: 50%;
	}
	@include e(ph) {
		background-image: url(../images/company_visual.jpg);
		background-repeat: no-repeat;
		background-position: right 8% top;
		background-size: cover;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 45%;

	}
	@include e(ourworks) {
		margin: 100px 0;
		@include e(wrap) {
			margin: 60px 0;
			display: flex;
			justify-content: space-between;
		}
		@include e(content) {
			width: 49%;
			background-color: #F5F5F5;
			padding: 100px 5% 30px 5%;
			background-size: auto 40px;
			background-repeat: no-repeat;
			background-position: center 60px;
		}
		@include e(first) {
			background-image: url(../images/icon_desktop-tower.svg);
		}
		@include e(second) {
			background-image: url(../images/icon_note-pencil.svg);
		}
	}
	@include e(list) {
		border-top: 1px solid #e0e2e9;
		border-bottom: 1px solid #e0e2e9;
		margin-top: -1px;
		@include e(all) {
			margin-top: 60px;
		}
		@include e(title) {
			padding-left: 60px;
			font-size: 20px;
			position: relative;
			padding-right: 50px;
			cursor: pointer;
			@include e(wrap) {
				display: flex;
				align-items: center;
				&:after {
					display: block;
					content: '';
					width: 8px;
					height: 8px;
					border-radius: 100%;
					background-color: #2f9667;
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			@include e(second) {
				font-size: 12px;
				padding-left: 30px;
				font-weight: normal;
				color: #BEBEBE;
			}

			&:after {
				display: block;
				content: '';
				width: 20px;
				height: 4px;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				background-color: #99A4B7;
			}

			&:before {
				display: block;
				content: '';
				width: 4px;
				height: 20px;
				position: absolute;
				right: 28px;
				top: 50%;
				transform: translateY(-50%);
				background-color: #99A4B7;
				transition: all, .3s;
			}

			&.active {
				&:before {
					opacity: 0;
				}
			}
		}
		@include e(content) {
			padding-left: 60px;
			padding-bottom: 80px;
			display: none;
			.table {
				tr {
					&:last-child {
						th, td {
							border-bottom: none;
						}
					}
				}
			}
		}

	}
	@include e(btn) {
		position: fixed;
		width: 90%;
		max-width: 600px;
		z-index: 4;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		@include e(link) {
			display: block;
			position: relative;
			padding: 20px 30px;
			background-color: rgba(#087e48, .68);
			box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
			/* Note: currently only Safari supports backdrop-filter */
			backdrop-filter: blur(10px);
			--webkit-backdrop-filter: blur(10px);
			line-height: 0;
			// background-color: rgba(0, 0, 0, 0);
			/* (plus shape's fill blended on top as a separate layer with 67.86% opacity) */
			text-align: center;
			&:hover {
				background-color: rgba(#2F9667, 1);
			}
			img {
				width: 100%;
				height: auto;
				max-width: 360px;
			}
		}
	}
	@include e(interviewlist) {
		margin: 70px 0 0 0;
		@include e(content) {
			background-color: #F5F5F5;
			display: flex !important;

			// flex-direction: row-reverse;
		}
		@include e(main) {
			width: 60%;
			padding: 70px 70px 70px 100px;
		}
		@include e(ph) {
			width: 40%;
			background-position: center;
			background-size: cover;
		}
		@include e(icon) {
			display: none;
		}
		@include e(read) {
			font-size: 28px;
			font-weight: bold;
			line-height: 1.5;
			padding-top: 20px;
			background-image: url(../images/icon_comment.svg);
			background-repeat: no-repeat;
			background-size: 10px;

		}
		@include e(profile) {
			display: flex;
			align-items: center;
			margin: 10px 0 30px 0;
		}
		@include e(name) {
			font-size: 20px;
			font-weight: bold;
		}
		@include e(job) {
			padding-left: 15px;
			font-size: 12px;
		}
		@include e(wrap) {
			position: relative;
			.slick-arrow {
				position: absolute;
				top: 45%;
				transform: translateY(-50%);
				z-index: 1;
				width: 40px;
				height: 40px;
				border-radius: 100%;
				background-color: #fff;
				border: none;
				cursor: pointer;
				transition: all, .3s;
				font-size: 0;
				color: transparent;
				background-position: center;
				background-size: 6px;
				background-repeat: no-repeat;
				&:hover {
					background-color: #b1e2a1;
				}
				&.slick-prev {
					left: 20px;
					background-image: url(../images/icon_arrow_prev.svg);
				}
				&.slick-next {
					right: 20px;
					background-image: url(../images/icon_arrow_next.svg);
				}
			}
		}

		.slick-dots {
			@extend %nolist;
			display: flex;
			justify-content: center;
			li {
				margin: 0 4px;
				button {
					background-color: #2F9667;
					width: 13px;
					height: 13px;
					border-radius: 100%;
					font-size: 0;
					color: transparent;
					padding: 0;
					border: none;
					opacity: .18;
					transition: all, .3s;
					cursor: pointer;
				}
				&.slick-active {
					button {
						opacity: 1;

					}
				}
			}
		}
	}
}


@media only screen and (max-width: 990px){



	.recruit {
		@include e(inner) {
			position: relative;
		}
		@include e(content) {
			width: 100%;
			margin-left: 0;
			// .subtitle {
			// 	br {
			// 		display: none !important;
			// 	}
			// }
		}
		@include e(ph) {
			width: 100%;
			position: static;
			height: 300px;
			margin-top: 40px;
		}
	}
}

@media only screen and (max-width: 870px){

	.recruit {
		@include e(ourworks) {
			@include e(wrap) {
				display: block;
				margin: 30px 0;
			}
			@include e(content) {
				width: 100%;
				margin: 20px 0;
			}
		}
	}
}


.interview {
	h2, h3, h4 {
		@extend %icontitle;
		background-position: left 15px;
		margin-top: 40px;
	}
	@include e(link) {
		padding: 50px 0;
		a {
			border: solid 1px #2f9667;
			background-color: #fff;
			padding: 20px;
			font-size: 16px;
			color: #2f9667;
			text-decoration: none;
			display: block;
			text-align: center;
			&:hover {
				background-color: #2f9667;
				color: #fff;
			}
		}
	}
	@include e(profile) {
		background-color: #F5F5F5;
		padding: 20px;
		border-radius: 500px;
		margin: 50px 0;
		@include e(sp) {
			display: none;
		}
		@include e(wrap) {

			display: flex;
			align-items: center;
		}
		@include e(ph) {
			width: 120px;
			height: 120px;
			border-radius: 100%;
			background-position: center;
			background-size: cover;
			margin-right: 30px;
		}
		@include e(content) {
			width: calc(100% - 150px);
		}
		@include e(job) {
			font-size: 12px;
		}
		@include e(name) {
			font-size: 22px;
			font-weight: bold;
			color: #2f9667;
			margin-top: -0.5em;
			margin-bottom: -0.5em;
		}
	}
}

.wrap_whpte {
	background-color: #fff;
}

/*-----------------------------------------------------*/
// スクロールアクション / フワッと表示
/*-----------------------------------------------------*/

.hreader_wrap {
	position: fixed;
	left: 0;top: 0;right: 0;height: 100px;
}

.fadein {
	opacity : 0;
	transform : translate(0, 50px);
	transition : all .7s;
	&.active {
		opacity : 1;
		transform : translate(0, 0);
	}
}


// Smartphone
@media only screen and (max-device-width: 640px),
only screen and (max-device-width: 667px),
only screen and (max-width: 480px){
    @import "_sp";
}